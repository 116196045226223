<template>
  <div class="card card-custom h-100 mb-5 filter-bar">
    <div class="card-body py-2 w-100 d-flex align-items-center">
      <h3 class="text-dark font-size-secondary m-0 mr-5">
        {{ 'filters.filters' }}
      </h3>

      <template v-for="(element, elementIndex) in elements">
        <!-- Button:Begin -->
        <div
          :key="elementIndex"
          v-if="element.type == 'button'"
          :id="`${element.name}-button-filter`"
        >
          <b-button
            v-for="(item, index) in element.items"
            :key="`${elementIndex}-${index}`"
            :variant="
              item.value == element.value ? 'filter-success' : 'filter-muted'
            "
            @click="changeFilter(elementIndex, item.value)"
            class="rounded-sm"
            >{{ item.name }}</b-button
          >
        </div>
        <!-- Button:End -->

        <!-- Dropdown:Begin -->
        <div :key="elementIndex" v-if="element.type == 'dropdown'">
          <b-dropdown
            id="dropdown-action"
            variant="filter-success"
            :text="getDropdownText(elementIndex, element.value)"
            class="ml-2"
          >
            <b-dropdown-item
              v-for="(item, index) in element.items"
              :key="`${elementIndex}-${index}-${item.value}`"
              @click="changeFilter(elementIndex, item.value)"
              :class="`${item.class ? item.class : ''}`"
            >
              {{ item.isDynamicName == 'false' ? item.name : `${item.name}` }}
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <!-- Dropdown:End -->

        <hr
          class="filter-divider-custom"
          v-if="elementIndex < elements.length - 1"
        />
      </template>

      <b-button
        variant="primary"
        class="rounded-sm btn-sm ml-auto"
        @click="refresh"
      >
        <i class="fas fa-sync refresh-icon pr-0"></i>
      </b-button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Filters',
  props: {
    elements: {
      type: Array,
      default: [],
    },
  },
  computed: {
    selectedFiltersQuery() {
      if (!this.elements) return;

      let selectedMapping = this.elements
        .filter((f) => f.value && f.value != 'all')
        .map((item) => {
          return item.name + '=' + item.value;
        })
        .join('&');

      return selectedMapping;
    },
  },
  methods: {
    getDropdownText(index, value) {
      return this.elements[index].items.find((f) => f.value == value).name;
    },
    changeFilter(index, value) {
      this.elements[index].value = value;
      this.$emit('selectedFilters', this.selectedFiltersQuery);
    },
    refresh() {
      this.$emit('selectedFilters', this.selectedFiltersQuery);
    },
  },
};
</script>

<style lang="scss" scoped>
.filter-bar {
  .refresh-icon {
    font-size: 14px;
  }
}
</style>
